<template>
<!-- 中间 -->
    <div style="position:relative;">
		<div class="container">
			<div class="contentItem lightBack" v-for="(item,index) in list" :key="index">
				<img class="img" :src="item.img" >
				<div>
					<div class="lightGrayFont" style="font-size:0.12rem;">{{item.title}}</div>
					<div class="whiteFont" style="font-size:0.16rem;" >
						<countTo :startVal='item.preNum' :endVal='item.num' :duration='3000'></countTo>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
	import countTo from 'vue-count-to';
import bus from '/src/assets/js/bus.js'

	export default {
		components: { countTo },
		data() {
		    return { 
		        list:[
					{ 
						title:'注册人数' , 
						num:0, 
						img:require('../../../assets/images/running.png') ,
						preNum:0 
					},
					{ 
						title:'今日运动人数' ,
						num:0, 
						img:require('../../../assets/images/num.png'),
						preNum:0 
					},
					{ 
						title:'运动人数' , 
						num:0, 
						img:require('../../../assets/images/average.png') ,
						preNum:0 
					},
					{ 
						title:'运动时长(时)' , 
						num:0, 
						img:require('../../../assets/images/people.png') ,
						preNum:0 
					},
				],
				numTimer:""
		    }
		},
		methods: {
			getData(){
				let that = this;
				let data = {
					area:window.communityname
				}
				let url = window.appHost+"/api/v1/screen/App_vital/vital?area="+window.communityname
				let result = window.ajaxRequest(url,JSON.stringify(data),"post")
				// console.log(result);
				// that.list[0].num = result.msg.onlineNum;
				that.list[0].preNum = that.list[0].num;
				that.list[1].preNum = that.list[1].num;
				that.list[2].preNum = that.list[2].num;
				that.list[3].preNum = that.list[3].num;
				that.list[0].num = result.msg.appUserCount;
				that.list[1].num = result.msg.day_sportsNum;
				that.list[2].num = result.msg.all_sportsNum;
				that.list[3].num = result.msg.timeNum;
			}
		},
		mounted () {
			let that = this;
			that.getData();
			bus.$on('now_area',res=>{
				that.getData();
			})
			that.numTimer = setInterval(() => {
				that.getData();
			}, 300000);
		},
		beforeDestroy(){
			bus.$off('now_area')
			clearInterval(this.numTimer)
		}
	}
</script>

<style scoped>
	.container{
		width: 7.82rem;
		height: 0.81rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.contentItem{
		width: 1.8rem;
		height: 0.81rem;
		display: flex;
		/* justify-content: center; */
        /* justify-content: space-around; */
        padding:0rem 0.1rem;
        box-sizing: border-box;
		align-items: center;
	}
	.img{
		width: 0.33rem;
		height: 0.34rem;
		margin-right: 0.1rem;
	}
</style>